import { useEffect } from 'react';

const useDisableHubSpotChat = (disable: boolean): void => {
    useEffect(() => {
        const handleChat = (): void => {
            if (window.HubSpotConversations) {
                if (disable) {
                    window.HubSpotConversations.widget.remove();
                } else {
                    window.HubSpotConversations.widget.load();
                }
            }
        };

        // Check if the HubSpot Conversations API is already loaded
        if (window.HubSpotConversations) {
            handleChat();
        } else {
            // Define the hsConversationsOnReady array if it doesn't exist
            window.hsConversationsOnReady = window.hsConversationsOnReady || [];
            // Push the chat handling function to the hsConversationsOnReady array
            window.hsConversationsOnReady.push(() => {
                handleChat();
            });
        }
    }, [disable]);
};

export default useDisableHubSpotChat;

import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import { Invite } from '@models/Invitation';
import { getConfig } from '@util/config';
import useAuth from '@hooks/useAuth';

const config = getConfig();
const usePendingInvites = () => {
    const { isLoggedIn } = useAuth();
    const response = useSWR<Invite[]>(() => (isLoggedIn ? Endpoints.user.invites({ active: undefined }) : undefined), {
        refreshInterval: config.invitesRefreshMs || 60 * 5_000,
    });
    const loading = !response.data && !response.error;
    return { ...response, loading };
};

export default usePendingInvites;

import { ReactNode } from 'react';
import { DialogTitle } from '@mui/material';
import cn from 'classnames';

export const TITLE_CLASSNAMES = 'text-2xl font-semibold tracking-tight';
export const SUBTITLE_CLASSNAMES = 'tracking-tight text-dark ';
export const SUBTITLE_TEXT_SIZE_CLASSNAME = 'text-lg';

type Props = {
    className?: string;
    disableSubtitleFormat?: boolean;
    iconEnd?: ReactNode;
    iconStart?: ReactNode;
    subTitle?: ReactNode;
    titleClassName?: string;
} & ({ children: ReactNode; title?: ReactNode } | { title: ReactNode; children?: ReactNode });
const PlutoDialogTitle = ({
    className,
    children,
    disableSubtitleFormat,
    iconEnd,
    iconStart,
    subTitle,
    title,
    titleClassName,
}: Props) => {
    return (
        <DialogTitle className={className}>
            <div
                className={cn(
                    'mx-4 flex items-center justify-center break-all text-center',
                    TITLE_CLASSNAMES,
                    titleClassName,
                )}
            >
                {iconStart && <span className="mr-2">{iconStart}</span>}
                <p className="">{title ?? children}</p>
                {iconEnd && <span className="ml-2 mt-1">{iconEnd}</span>}
            </div>
            {subTitle && (
                <div className={cn({ [SUBTITLE_CLASSNAMES]: !disableSubtitleFormat }, 'mt-1 text-center')}>
                    {subTitle}
                </div>
            )}
        </DialogTitle>
    );
};

export default PlutoDialogTitle;

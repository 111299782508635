import { ButtonProps, CircularProgress } from '@mui/material';
import Button from '@components/Button';

type LoadingButtonProps = ButtonProps & { loading?: boolean };

export const LoadingIndicator = ({ size = 24 }: { size?: number }) => {
    return <CircularProgress variant="indeterminate" size={size} />;
};

const LoadingButton = ({
    loading = false,
    variant = 'contained',
    color = 'primary',
    children,
    disabled,
    ...props
}: LoadingButtonProps) => {
    return (
        <Button disabled={loading || disabled} color={color} variant={variant} {...props}>
            {loading ? <LoadingIndicator /> : children}
        </Button>
    );
};

export default LoadingButton;

import Experiment from '@models/Experiment';
import { hasPermission } from '@util/PermissionUtil';
import { CrudPermissions, PermissionName, FeatureResponse } from '@models/Permission';
import Endpoints from '../services/Endpoints';
import useSWR from 'swr';

export type ExperimentPermissions = ReturnType<typeof useExperimentPermissions>;

const useExperimentPermissions = (experiment?: Experiment | null, isPublic?: boolean) => {
    const { data, error } = useSWR<FeatureResponse>(() =>
        experiment && !isPublic ? Endpoints.lab.experiment.features({ experimentId: experiment.uuid }) : null,
    );
    const features = data?.experiment_features;

    const check = (requires: PermissionName | PermissionName[], any = false) => {
        return hasPermission(experiment, { requires, any });
    };

    const attachments: CrudPermissions = {
        canDownload: check(PermissionName.download_experiment_attachments),
        canEdit: check(PermissionName.edit_experiment_attachments),
        canView: check(PermissionName.view_experiment_attachments),
        canCreate: check(PermissionName.create_experiment_attachments),
        canArchive: check(PermissionName.archive_experiment_attachments),
    };

    return {
        canEdit: check(PermissionName.edit_experiment),
        canAddPlot: check(PermissionName.edit_experiment),
        viewReport: check(PermissionName.view_experiment_report),
        canArchive: check(PermissionName.archive_experiment),
        members: {
            canView: experiment?.share_level !== 'public' || check(PermissionName.edit_experiment),
            canInvite: check(PermissionName.invite_experiment_users),
        },
        changeHistory: {
            canView: check(PermissionName.view_experiment_history),
        },
        attachments,
        features,
        error,
    };
};

export default useExperimentPermissions;

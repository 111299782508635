import Link from 'next/link';
import { PropsWithChildren } from 'react';
import copy from '@copy/Copy';
import cn, { Argument as ClassValue } from 'classnames';
import NavBar from '@/src/components/NavBar';
import Footer from '@/src/components/Footer';
import BaseMeta from '@layouts/BaseMeta';
import { HubspotProvider } from 'next-hubspot';

export type MaxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl';

export type LayoutMetaProps = {
    title?: string;
    titleFull?: string;
    description?: string;
    metaImageUrl?: string;
    metaImageAltText?: string;
    metaVideoUrl?: string;
    ogType?: string;
    canonical?: string;
};

export type DefaultLayoutProps = PropsWithChildren<
    LayoutMetaProps & {
        showLogo?: boolean;
        className?: ClassValue;
        container?: boolean;
        noMargin?: boolean;
        noMarginTop?: boolean;
        maxWidth?: MaxWidth;
        fullWidth?: boolean;
        showHeader?: boolean;
        showFooter?: boolean;
        noMetadata?: boolean;
    }
>;

export function getMaxWidthClasses(maxWidth?: MaxWidth | null) {
    return {
        'max-w-sx': maxWidth === 'xs',
        'max-w-sm': maxWidth === 'sm',
        'max-w-md': maxWidth === 'md',
        'max-w-lg': maxWidth === 'lg',
        'max-w-xl': maxWidth === 'xl',
        'max-w-2xl': maxWidth === '2xl',
        'max-w-3xl': maxWidth === '3xl',
        'max-w-4xl': maxWidth === '4xl',
        'max-w-5xl': maxWidth === '5xl',
        'max-w-6xl': maxWidth === '6xl',
        'max-w-7xl': maxWidth === '7xl',
    };
}

export default function DefaultLayout({
    children,
    className,
    showLogo,
    container = true,
    title,
    titleFull,
    description = copy.siteDescription,
    metaImageUrl = copy.siteMetaImageUrl,
    metaImageAltText = `${copy.companyName} Logo`,
    metaVideoUrl = copy.siteMetaVideoUrl,
    ogType,
    canonical,
    noMargin = false,
    maxWidth,
    fullWidth = false,
    noMetadata = false,
    showHeader = false,
    showFooter = false,
}: DefaultLayoutProps): JSX.Element {
    const widthClasses = fullWidth ? {} : getMaxWidthClasses(maxWidth);
    // Google recommends titles no longer than 60 and descriptions no longer than 160
    const metaTitle = (titleFull ?? (title ? `${title} | ${copy.companyName}` : copy.siteTitleLong))?.substring(0, 60);
    return (
        <>
            <HubspotProvider>
                {!noMetadata && (
                    <BaseMeta
                        title={metaTitle}
                        description={description}
                        imageUrl={metaImageUrl}
                        imageAltText={metaImageAltText}
                        videoUrl={metaVideoUrl}
                        ogType={ogType}
                        canonical={canonical}
                    />
                )}
                <div className={cn('h-full min-h-screen', { 'flex flex-col justify-between': showFooter })}>
                    <div className={cn('flex h-full flex-col', { 'min-h-screen': !showFooter })}>
                        {showHeader && <NavBar sticky={false} />}
                        <div
                            className={cn(
                                'flex h-full flex-grow flex-col',
                                {
                                    container: !maxWidth && container && !fullWidth,
                                    'mx-auto': maxWidth && !fullWidth,
                                    'mb-24': !noMargin && showFooter,
                                    'mb-24 md:mb-0': !noMargin && !showFooter,
                                    ...widthClasses,
                                },
                                className,
                            )}
                        >
                            <main className={cn('flex h-full flex-grow flex-col', {})}>
                                {showLogo && (
                                    <Link href="/" passHref legacyBehavior>
                                        <p className="cursor-pointer text-primary hover:opacity-70">
                                            <img
                                                className="mb-6"
                                                src="https://cdn.bfldr.com/9AK17BV1/at/wkvwmg2jt85z4xg6f52wnw/Pluto_Logo?width=180"
                                                alt="Pluto Bio Logo"
                                            />
                                        </p>
                                    </Link>
                                )}
                                {children}
                            </main>
                        </div>
                    </div>
                    {showFooter && <Footer />}
                </div>
            </HubspotProvider>
        </>
    );
}

// hooks/useInviteHref.ts
import { useRouter } from 'next/router';

const useLoginHref = (baseHref: string) => {
    const router = useRouter();
    const queryString = router.asPath.split('?')[1] || '';
    const dynamicHref = `${baseHref}?${queryString}`;

    return dynamicHref;
};

export default useLoginHref;

import { Dialog, DialogContent } from '@mui/material';
import { useRef } from 'react';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import SupportForm from '@components/support/SupportForm';

export type Props = {
    onCancel: () => void;
    open?: boolean;
};
const SupportDialog = ({ onCancel, open = false }: Props) => {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogCloseButton onClose={() => onCancel()} />
            <PlutoDialogTitle title="Email support" className="!pb-0" />
            <DialogContent className="my-4 space-y-4" ref={ref}>
                <SupportForm />
            </DialogContent>
        </Dialog>
    );
};
export default SupportDialog;

import useAuth from '@hooks/useAuth';
import { pluralize } from '@util/ObjectUtil';
import Button from '@components/Button';
import cn from 'classnames';
import { useTrialContext } from '@contexts/TrialContext';
import { ReactNode } from 'react';
import { DateFormat, formatDate } from '@util/DateUtil';
import { isDefined } from '@util/TypeGuards';
import useOrganizationTrial from '@/src/hooks/useOrganizationTrial';

const Badge = ({ children }: { children: ReactNode }) => {
    return (
        <span className="mx-[3px] inline-block rounded bg-secondary px-[4px] py-[1px] font-semibold text-white">
            {children}
        </span>
    );
};

const DurationLabel = () => {
    const { trialActive, trialExpiresAt, daysRemaining } = useTrialContext();
    const { hasBillingID } = useOrganizationTrial();
    const trialOrPlan = hasBillingID ? 'plan' : 'trial';
    if (!trialActive) {
        return trialExpiresAt ? (
            <span>
                Your {trialOrPlan} ended on {formatDate(new Date(trialExpiresAt), DateFormat.DATE_SHORT)}.
            </span>
        ) : (
            <span>Your {trialOrPlan} has ended.</span>
        );
    }

    if (isDefined(daysRemaining) && daysRemaining > 0) {
        return (
            <span className="">
                Just&nbsp;
                <Badge>
                    {daysRemaining}&nbsp;{pluralize(daysRemaining, 'day', 'days')}
                </Badge>
                &nbsp;
                <span>left in your {trialOrPlan} and you still have tasks&nbsp;left&nbsp;to&nbsp;complete!</span>
            </span>
        );
    }

    return (
        <span>
            Less than <Badge>1 day</Badge>&nbsp;
            <span>left in your {trialOrPlan} and you still have tasks&nbsp;left&nbsp;to&nbsp;complete!</span>
        </span>
    );
};

type Props = { innerClassName?: string };
const TrialBanner = ({ innerClassName }: Props) => {
    const { showBanner, setTasksOpen } = useTrialContext();

    const { user } = useAuth();
    if (!user || !showBanner) {
        return null;
    }

    return (
        <div className="h-10 flex-shrink-0 flex-grow-0 bg-indigo-200 text-dark">
            <div className={cn('md:flex md:justify-center', innerClassName)}>
                <div className="flex items-center justify-between">
                    <DurationLabel />

                    <div>
                        <span>
                            <Button variant="text" color="primary" onClick={() => setTasksOpen(true)}>
                                See&nbsp;your&nbsp;tasks
                            </Button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrialBanner;

import { defaultProps, IconProps } from '@components/icons/CustomIcons';

export const HomeIcon = (props?: IconProps) => {
    const { height, width } = { ...defaultProps, ...props };
    return (
        <svg
            width={width}
            height={height}
            className="p-px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="3"
                y="1"
                width="20"
                height="22"
                rx="2"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13 15v-5M17 15V8M9 15v-3M4 6H1M4 10H1M4 14H1M4 18H1"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default HomeIcon;

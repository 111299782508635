import useMatchMutate from '@hooks/useMatchMutate';

import Experiment from '@models/Experiment';
import Endpoints from '@services/Endpoints';
import { useSWRConfig } from 'swr';

const useExperimentCache = (experiment?: Pick<Experiment, 'uuid' | 'pluto_id'> | null) => {
    const { startsWithMutate } = useMatchMutate();
    const { mutate } = useSWRConfig();
    const refreshPlots = async () => {
        if (!experiment) {
            return;
        }
        const plotsUrlUuid = Endpoints.lab.experiment.plotsV2({ experimentId: experiment.uuid });
        const plotsUrlPlutoId = Endpoints.lab.experiment.plotsV2({ experimentId: experiment.uuid });
        const plotsRefresh = mutate(Endpoints.lab.experiment.plotsV2({ experimentId: experiment.uuid }), [], true);
        await Promise.all([plotsRefresh, startsWithMutate(plotsUrlPlutoId), startsWithMutate(plotsUrlUuid)]);
    };

    const refreshExperiment = async () => {
        if (!experiment) {
            return;
        }
        const experimentUUIDUrl = Endpoints.lab.experiment.base(experiment.uuid);
        const experimentPlutoIDUrl = Endpoints.lab.experiment.base(experiment.pluto_id);
        await Promise.all([startsWithMutate(experimentUUIDUrl), startsWithMutate(experimentPlutoIDUrl)]);
    };

    return { refreshPlots, refreshExperiment };
};
export default useExperimentCache;

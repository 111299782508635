import TextInput, { TextInputProps } from '@components/forms/TextInput';
import { useField } from 'formik';
import { ChangeEventHandler, HTMLProps, ReactNode } from 'react';
import { blankToNull, isNotBlank } from '@util/StringUtil';

type Props = {
    name: string;
    label: ReactNode;
    subLabel?: string;
    nullable?: boolean;
    validateOnBlur?: boolean;
} & Omit<HTMLProps<HTMLInputElement>, 'label' | 'name'> &
    TextInputProps;
const TextInputField = ({
    name,
    label,
    subLabel,
    nullable,
    onChange,
    onValueChange,
    validateOnBlur = false,
    onBlur,
    ...htmlProps
}: Props) => {
    const [inputProps, meta, helpers] = useField<string | null | number>(name);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (onValueChange) {
            return;
        }
        if (onChange) {
            onChange(e);
            return;
        }

        const originalValue = e.target.value;
        let inputValue: string | number | null = e.target.value;
        if (nullable) {
            inputValue = blankToNull(inputValue);
        }

        if (
            htmlProps.type === 'number' &&
            inputValue !== null &&
            !Number.isNaN(Number(inputValue)) &&
            isNotBlank(originalValue)
        ) {
            inputValue = Number(inputValue);
        }
        helpers.setValue(inputValue);
    };

    return (
        <TextInput
            name={name}
            value={(inputProps.value as string) ?? ''}
            onChange={handleChange}
            touched={meta.touched}
            onValueChange={onValueChange}
            useFormikError
            label={label}
            subLabel={subLabel}
            error={meta.error && meta.touched ? meta.error : null}
            onBlur={(e) => {
                if (validateOnBlur) {
                    inputProps.onBlur(e);
                }
                onBlur?.(e);
            }}
            {...htmlProps}
        />
    );
};

export default TextInputField;

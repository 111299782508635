type Props = { text?: string; my?: number | string; className?: string };

const Line = () => <hr className="flex-1 border-gray-300" />;

const HorizontalLine = (props: Props) => {
    const { text, className = 'my-8' } = props;
    return (
        <div className={`flex items-center text-center ${className}`}>
            <Line />
            {text && <span className="pa-4 z-10 mx-2 bg-white text-gray-500">{text}</span>}
            <Line />
        </div>
    );
};

export default HorizontalLine;

import React, { useEffect, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import cn from 'classnames';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import { TimeoutValue } from '@util/ObjectUtil';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/cjs/styles/prism';

const DEFAULT_SUCCESS_TIMEOUT = 4000;

type Props = {
    value: string;
    isPrivate?: boolean;
    initialPrivate?: boolean;
    successTimeout?: number;
    inputClasses?: string;
    noMargin?: boolean;
    multilineRows?: number;
    useSyntaxHighlight?: boolean;
    language?: string;
};

const CopyTextInput = ({
    value,
    isPrivate = false,
    initialPrivate = false,
    successTimeout = DEFAULT_SUCCESS_TIMEOUT,
    inputClasses,
    noMargin = false,
    multilineRows,
    useSyntaxHighlight = false,
    language = 'python',
}: Props) => {
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    const [show, setShow] = useState(!initialPrivate);
    const timeoutRef = useRef<TimeoutValue | null>(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current ?? undefined);
        };
    }, []);

    const handleCopyClicked = async () => {
        if (navigator.clipboard && value) {
            inputRef.current?.select();
            await navigator.clipboard.writeText(value);
            setSuccess(true);
            timeoutRef.current = setTimeout(() => {
                timeoutRef.current = null;
                setSuccess(false);
            }, successTimeout);
        }
    };

    return (
        <div>
            <div className={cn('form-field space-y-1 p-[1px]', { 'no-margin': noMargin })}>
                <div className="relative">
                    {useSyntaxHighlight ? (
                        <SyntaxHighlighter
                            language={language}
                            style={tomorrow}
                            className={cn('field-input h-72', inputClasses)}
                        >
                            {value}
                        </SyntaxHighlighter>
                    ) : multilineRows ? (
                        <textarea
                            ref={inputRef as React.RefObject<HTMLTextAreaElement>}
                            value={value}
                            className={cn('field-input', inputClasses, {
                                '!pr-28': success && isPrivate,
                                '!pr-16': !success && isPrivate,
                                '!pr-20': success && !isPrivate,
                                '!pr-8': !success && !isPrivate,
                                'no-margin': noMargin,
                            })}
                            onFocus={() => handleCopyClicked()}
                            onClick={() => handleCopyClicked()}
                            readOnly
                            rows={multilineRows}
                        />
                    ) : (
                        <input
                            ref={inputRef as React.RefObject<HTMLInputElement>}
                            type={show ? 'text' : 'password'}
                            value={value}
                            className={cn('field-input', inputClasses, {
                                '!pr-28': success && isPrivate,
                                '!pr-16': !success && isPrivate,
                                '!pr-20': success && !isPrivate,
                                '!pr-8': !success && !isPrivate,
                                'no-margin': noMargin,
                            })}
                            onFocus={() => handleCopyClicked()}
                            onClick={() => handleCopyClicked()}
                            readOnly
                        />
                    )}
                    <span className="absolute right-0 top-0 z-10 flex h-full items-center space-x-1 pr-1">
                        {success ? (
                            <span className="px-2 font-semibold text-primary">Copied!</span>
                        ) : (
                            <IconButton
                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                size="small"
                                onClick={handleCopyClicked}
                                color="primary"
                            >
                                <CopyIcon height={18} width={18} />
                            </IconButton>
                        )}
                        {isPrivate && (
                            <IconButton
                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                size="small"
                                onClick={() => setShow((current) => !current)}
                            >
                                {show ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
                            </IconButton>
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CopyTextInput;

import React, { HTMLProps, ReactNode } from 'react';
import cn from 'classnames';
import { Value as ClassValue } from 'classnames';

export const CARD_PADDING_Y = 'py-6 md:py-16';
export const CARD_PADDING_X = 'px-4 sm:px-6 md:px-8 lg:px-16';

export const DEFAULT_CARD_PADDING_CLASSES = `${CARD_PADDING_X} ${CARD_PADDING_Y}`;
export const NO_GUTTER_LEFT_CLASSES = '-ml-6 md:-ml-16';
export const NO_GUTTER_LEFT_CLASSES_SM_ONLY = '-ml-6 md:ml-0';

export const NO_GUTTER_RIGHT_CLASSES = '-mr-6 md:-mr-16';
export const NO_GUTTER_RIGHT_CLASSES_SM_ONLY = '-mr-6 md:mr-0';

type CardProps = {
    children: ReactNode;
    className?: ClassValue;
    noPadding?: boolean;
    noShadow?: boolean;
    paddingX?: boolean;
    paddingY?: boolean;
} & HTMLProps<HTMLDivElement>;
type NoGutterProps = { children: ReactNode; className?: ClassValue; left?: boolean; right?: boolean; smOnly?: boolean };
export const NoGutter: React.FunctionComponent<NoGutterProps> = ({
    children,
    className,
    smOnly = false,
    ...props
}: NoGutterProps) => {
    const bothUndefined = props.left === undefined && props.right === undefined;
    const left = bothUndefined ? true : props.left;
    const right = bothUndefined ? true : props.right;
    return (
        <div
            className={cn(className, {
                [NO_GUTTER_LEFT_CLASSES]: left && !smOnly,
                [NO_GUTTER_RIGHT_CLASSES]: right && !smOnly,
                [NO_GUTTER_LEFT_CLASSES_SM_ONLY]: left && smOnly,
                [NO_GUTTER_RIGHT_CLASSES_SM_ONLY]: right && smOnly,
            })}
        >
            {children}
        </div>
    );
};

export const CardContent = ({ children }: { children: ReactNode }) => {
    return <div className={cn(CARD_PADDING_X)}>{children}</div>;
};

const Card: React.FunctionComponent<CardProps> = ({
    children,
    className,
    noPadding = false,
    noShadow = false,
    paddingX = true,
    paddingY = true,

    ...props
}: CardProps) => {
    return (
        <div
            {...props}
            className={cn(className, 'overflow-hidden', {
                [CARD_PADDING_X]: !noPadding && paddingX,
                [CARD_PADDING_Y]: !noPadding && paddingY,
                ['rounded-2xl bg-white']: !noShadow,
            })}
        >
            {children}
        </div>
    );
};
export default Card;

import { useEffect } from 'react';

export default function HubspotForm() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: '8961313',
                    formId: '46689108-c0c7-4095-b607-a8f2457089de',
                    target: '#hubspot-form-wrapper',
                });
            }
        });
    }, []);

    return <div id="hubspot-form-wrapper" className="hubspotForm"></div>;
}

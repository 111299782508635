import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Link } from '@mui/material';
import NextLink from 'next/link';
import LoadingButton from '@components/LoadingButton';
import HorizontalLine from '@components/HorizontalLine';
import { Alert } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Schemas } from '@components/forms/Forms';
import useAuth from '@hooks/useAuth';
import Button from '@components/Button';
import Logger from '@util/Logger';
import { ApiError } from '@services/ApiError';
import TextInputField from '@components/forms/TextInputField';
import { appendQueryParams } from '@services/QueryParams';
import GoogleIdentityButton from '@components/auth/GoogleIdentityButton';
import useInviteHref from '@hooks/useInviteHref';
import useInviteValidation from '@hooks/useInviteValidation'; // Update the path accordingly
import useExtractedEmailFromURL from '@hooks/extractEmailFromURL';

const logger = Logger.make('LoginForm');

type LoginFormProps = {
    signupLinkText?: string;
    title?: string;
    subTitle?: string;
    disableSignup?: boolean;
    signupHref?: string;
};

const LoginSchema = Yup.object({
    email: Schemas.email,
    password: Schemas.password,
});
type FormValues = Yup.InferType<typeof LoginSchema>;

const LoginForm = ({ signupLinkText = 'Sign up', disableSignup, signupHref = '/signup' }: LoginFormProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const [authError, setAuthError] = useState<string | null>(null);
    const [showLoggedInMessage, setShowLoggedInMessage] = useState(true);
    const { user, logout, loginSuccessRedirect, submitLogin } = useAuth();
    const [needsRedirect, setNeedsRedirect] = useState(false);
    const isValidInvite = useInviteValidation();
    const router = useRouter();
    // Hide Google for certain organizations
    // TODO - Move this to a feature flag in future
    const shouldHideGoogleButton = router.asPath.includes('novo-nordisk');

    const signupHrefAppended = useInviteHref(signupHref);
    const extractedEmail = useExtractedEmailFromURL();
    const initialValues: FormValues = { email: extractedEmail || '', password: '' };

    const handleSubmit = async (values: FormValues, formik: FormikHelpers<FormValues>) => {
        try {
            setAuthError(null);
            setShowLoggedInMessage(false);
            setNeedsRedirect(true);
            const user = await submitLogin(values);
            if (!user) {
                logger.error('Unable to fetch user after logging in - can not redirect them.');
                setAuthError('An unexpected error occurred while trying to log in. Please try again later.');
                formik.setSubmitting(false);
                setNeedsRedirect(false);
            } else {
                loginSuccessRedirect(user);
            }
        } catch (error) {
            setNeedsRedirect(false);
            setAuthError(ApiError.getMessage(error));
            logger.error(error);
            setShowLoggedInMessage(true);
            formik.setSubmitting(false);
        }
    };

    useEffect(() => {
        if (needsRedirect && user) {
            logger.info('[useEffect] sending user to new page', user);
            loginSuccessRedirect(user);
        }
    }, [user, needsRedirect]);

    if (user && showLoggedInMessage) {
        return (
            <div>
                <Alert
                    severity="success"
                    action={
                        <Button onClick={() => logout()} color="inherit" data-cy="logout-btn">
                            Log&nbsp;Out
                        </Button>
                    }
                >
                    You are signed in as <span className="font-semibold">{user.email}</span>
                    <br />
                    <NextLink cy-data="labspace-link" href="/labspace" passHref>
                        Go to labspace
                    </NextLink>
                </Alert>
            </div>
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={LoginSchema}
        >
            {({ isSubmitting, values }) => (
                <>
                    {authError && (
                        <Alert
                            severity="error"
                            onClose={() => setAuthError(null)}
                            className="mb-8"
                            data-cy="auth-error-alert"
                        >
                            {authError}
                        </Alert>
                    )}
                    {!shouldHideGoogleButton && (
                        <>
                            <GoogleIdentityButton
                                login={true}
                                onConnectStarted={() => setNeedsRedirect(true)}
                                onCancel={() => setNeedsRedirect(false)}
                            />
                            <HorizontalLine text="or" className="my-10" />
                        </>
                    )}
                    <Form noValidate>
                        <TextInputField
                            label="Email address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            data-cy="email"
                        />
                        <TextInputField
                            name="password"
                            data-cy="password"
                            className="mb-2"
                            autoComplete="current-password"
                            type={showPassword ? 'text' : 'password'}
                            noMargin
                            label={
                                <div className="space-between flex">
                                    <span className="field-label">Password</span>
                                    <NextLink
                                        href={appendQueryParams('/forgot-password', { email: values.email })}
                                        passHref
                                        className="link text-xs"
                                        tabIndex={-1}
                                    >
                                        Forgot?
                                    </NextLink>
                                </div>
                            }
                        />
                        <label className="form-field inline-flex cursor-pointer items-center pl-1">
                            <input
                                type="checkbox"
                                className="rounded text-indigo-500"
                                checked={showPassword}
                                onChange={(e) => setShowPassword(e.target.checked)}
                                data-cy="show-password-input"
                            />
                            <span className="ml-2">Show password</span>
                        </label>

                        <div className="mb-4">
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                loading={isSubmitting}
                                disabled={isSubmitting || needsRedirect}
                                data-cy="submit"
                            >
                                Log In
                            </LoadingButton>
                        </div>
                    </Form>
                    {!disableSignup && isValidInvite && (
                        <div className="flex justify-center ">
                            <span className="mr-1 text-gray-500">{"Don't have an account? "}</span>
                            <Link href={signupHrefAppended} variant="body2">
                                {signupLinkText}
                            </Link>
                        </div>
                    )}
                </>
            )}
        </Formik>
    );
};

export default LoginForm;

import { Autocomplete } from '@mui/material';
import { useMemo, useState } from 'react';
import { Checkbox, Chip, TextField, FilterOptionsState, Box } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { isBlank, isNotBlank } from '@util/StringUtil';
import Button from '@components/Button';
import { useField } from 'formik';
import cn from 'classnames';
import { FormikFieldError } from '@components/forms/FieldError';
import { matchSorter } from 'match-sorter';
import { isDefined } from '@util/TypeGuards';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Logger from '@util/Logger';
import { pluralize } from '@util/ObjectUtil';
import { ClipboardIcon, TrashIcon } from '@heroicons/react/outline';
import useSimpleClipboard from '@hooks/useSimpleClipboard';

const logger = Logger.make('Picker');

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export type ValueType = string[];

type Option = { label: string; value: string };
type Options = Option[];

const filterOptions =
    (sortOptions: boolean) =>
    (options: Options, { inputValue }: FilterOptionsState<string>): Options => {
        if (!sortOptions) return options;
        const sorted: Option[] = inputValue
            ? matchSorter(options.slice(1), inputValue, {
                  threshold: matchSorter.rankings.CONTAINS,
                  keys: ['label'],
              })
            : options.slice(1);

        if (isDefined(options[0])) {
            sorted.unshift(options[0]);
        }
        return sorted;
    };

type Props = {
    disabled?: boolean;
    itemName: string;
    maxItems?: number;
    name: string;
    noOptionsText?: string;
    options: string[] | Options;
    placeholder?: string;
    sortValues?: (newValues: Options) => Options;
    sortOptions?: boolean;
    showCopyButton?: boolean;
};
/**
 * A Formik form field for picking multiple options
 * @param {Props} props
 * @return {JSX.Element}
 * @constructor
 */
const Picker = ({
    disabled,
    itemName,
    maxItems,
    name,
    noOptionsText = 'No options found',
    options: optionsProp,
    placeholder = 'Select...',
    sortValues,
    sortOptions = true,
    showCopyButton = true,
}: Props) => {
    /** the formik field values */
    const [{ value = [] }, { error: formError, touched }, { setValue }] = useField<ValueType>(name);
    const pluralName = `${itemName}s`;
    const formatOptions = () => {
        // Check if options are an array of strings
        if (optionsProp && optionsProp.length > 0 && typeof optionsProp[0] === 'string') {
            return optionsProp.map((option) => ({ label: option, value: option })) as Options;
        }
        // Check if optionsProp are already in the correct format (array of objects)
        else if (
            optionsProp &&
            optionsProp.length > 0 &&
            (optionsProp as Options)[0].label &&
            (optionsProp as Options)[0].value
        ) {
            return optionsProp as Options;
        }
        // Handle other cases or empty options
        else {
            return [];
        }
    };
    const options = useMemo<Options>(() => formatOptions(), [optionsProp]);

    /** is picker menu open */
    const [open, setOpen] = useState(false);

    /** the text the user has entered into the search input */
    const [searchTerm, setSearchTerm] = useState<string>('');

    const { copyTextToClipboard, showSuccess: copySuccess } = useSimpleClipboard();

    const removeOption = (option: string) => {
        const updatedOptions = value.filter((t) => t !== option);
        logger.debug(`[removeOption] removing "${option}"`, { original: value, updatedOptions });
        setValue(updatedOptions);
    };

    const items = useMemo<Options>(() => {
        if (!options?.length) {
            return [];
        }

        const items: Options = options.filter((item) => item.label.toLowerCase().includes(searchTerm?.toLowerCase()));
        const dataCount = items.length ?? 0;

        if (!isBlank(searchTerm)) {
            const searchResultText = `Showing ${dataCount} ${pluralName} matching\xa0"${searchTerm}"`;
            const noResultsText = `No results found for\xa0"${searchTerm}"`;
            const finalString = dataCount > 0 ? searchResultText : noResultsText;
            items.unshift({ label: finalString, value: finalString });
        } else {
            const string = `Showing first ${dataCount}\xa0${pluralName}`;
            items.unshift({ label: string, value: string });
        }

        return items;
    }, [options, value, searchTerm]);

    const additionalSelectionDisabled = isDefined(maxItems) && value.length >= maxItems;

    const isFirstItem = (option: Option) => {
        return option === items[0];
    };

    /**
     * Decide if the option is disabled. Disabled if the max number of items has been selected and the item is
     * not currently selected (allows for unselecting) or if the item is the first in the list,
     * which is not a real selection.
     * @param {Option} option
     * @return {boolean}
     */
    const isOptionDisabled = (option) => {
        return isFirstItem(option) || (additionalSelectionDisabled && !value.includes(option));
    };

    return (
        <div className={cn('form-field input-inline-block mt-2', { 'has-error': Boolean(formError && touched) })}>
            <Autocomplete
                multiple
                style={{ width: '100%' }}
                open={open}
                disabled={disabled}
                disableCloseOnSelect
                clearOnBlur={false}
                selectOnFocus
                handleHomeEndKeys
                noOptionsText={noOptionsText}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                value={value}
                getOptionDisabled={isOptionDisabled}
                filterOptions={filterOptions(sortOptions)}
                onChange={(_e, newValues, reason) => {
                    if (reason === 'clear') {
                        return;
                    }

                    const values = (sortValues?.(newValues) ?? newValues ?? []).map((v) => (v?.value ? v.value : v));
                    setValue(values);
                }}
                getOptionLabel={(option) => option?.label ?? ''}
                options={items}
                isOptionEqualToValue={(option, value) => option.value === value}
                inputValue={searchTerm ?? ''}
                onInputChange={(_event, newInputValue, reason) => {
                    if (reason === 'reset') {
                        return;
                    }
                    const regex = new RegExp(/\s*,\s*|\s+/g);

                    if (newInputValue && newInputValue.match(regex)) {
                        logger.debug('[onInputChange] new value ', newInputValue);
                        const bulkValues = newInputValue.split(regex);
                        const newValues = [...value];
                        bulkValues.forEach((bv) => {
                            if (!newValues.includes(bv) && isNotBlank(bv)) {
                                newValues.push(bv);
                            }
                        });
                        logger.debug('[onInputChanged] setting new values to', newValues);
                        setValue(newValues);
                        return;
                    }

                    setSearchTerm(newInputValue);
                }}
                renderOption={(props, option, { selected }) => {
                    const matches = match(option.label, searchTerm ?? '', { insideWords: true });
                    const parts = parse(option.label, matches);
                    const isFirst = option.label === items[0].label;
                    return (
                        <Box component="li" {...props} key={option.value + Math.random()}>
                            {!isFirstItem(option) && (
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8, padding: 0 }}
                                    checked={selected}
                                    size="small"
                                />
                            )}
                            {isFirst ? (
                                option.label
                            ) : (
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            className={cn({ 'font-semibold text-indigo-700': part.highlight })}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </Box>
                    );
                }}
                renderTags={() => null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        placeholder={placeholder}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            className: 'flex-nowrap',
                            endAdornment: params.InputProps.endAdornment,
                        }}
                    />
                )}
            />

            <div className="mt-2 flex max-h-[180px] flex-wrap overflow-y-auto">
                {value.map((factor, index) => {
                    return (
                        <Chip
                            key={`${factor}-${index}`}
                            onDelete={() => removeOption(factor)}
                            label={options.find((o) => o.value === factor)?.label ?? factor}
                            className="mb-0.5 mr-0.5"
                            disabled={disabled}
                        />
                    );
                })}
            </div>

            {value.length > 0 && (
                <div className="mt-1 flex justify-between">
                    {showCopyButton && (
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => copyTextToClipboard(value.join(', '))}
                            startIcon={<ClipboardIcon width={18} />}
                        >
                            {copySuccess ? 'Copied!' : 'Copy'}
                        </Button>
                    )}

                    <Button
                        variant="text"
                        color="inherit"
                        onClick={() => setValue([])}
                        startIcon={<TrashIcon width={18} className={'text-error'} />}
                        disabled={disabled}
                    >
                        <span className="text-error">
                            {value.length === options.length
                                ? 'Clear all'
                                : `Remove ${value.length} ${pluralize(value.length, itemName ?? '', pluralName)}`}
                        </span>
                    </Button>
                </div>
            )}

            <FormikFieldError name={name} />
        </div>
    );
};

export default Picker;

import { forwardRef } from 'react';
import { ButtonGroupItem } from '@components/ButtonGroup';
import cn from 'classnames';

type Props = {
    item: ButtonGroupItem;
    onClick?: () => void;
    onDark?: boolean;
    selected?: boolean;
    disabled?: boolean;
    className?: string;
    selectedItemClassName?: string;
};

const ButtonGroupItemView = forwardRef<HTMLDivElement, Props>(
    ({ item, onClick, onDark: _onDark, selected, disabled, className, selectedItemClassName }, ref) => {
        const onDark = _onDark ?? item.onDark;

        return (
            <div
                onClick={() => onClick?.()}
                key={`item_${item.value}`}
                ref={ref}
                data-selected={selected ? 'selected' : undefined}
                className={cn(
                    className,
                    'ease focus:shadow-outline text-s mr-1 flex flex-1 cursor-pointer flex-col items-center justify-center whitespace-nowrap rounded-3xl px-4 py-2 text-center font-semibold tracking-tight outline-none transition duration-200 last:mr-0 md:text-sm',
                    {
                        '!cursor-default text-default': item.disabled || disabled,
                        'bg-white': selected && !onDark && !Boolean(selectedItemClassName),
                        'bg-secondary': selected && onDark,
                        'hover:bg-gray-600/50': !selected && !item.disabled && !disabled && onDark,
                        'hover:bg-white/60': !selected && !item.disabled && !disabled && !onDark,
                        'text-white': !item.disabled && !disabled && onDark,
                        'text-primary': !item.disabled && !disabled && !onDark,
                    },
                    selectedItemClassName,
                )}
            >
                <div>{item.label}</div>
            </div>
        );
    },
);

export default ButtonGroupItemView;

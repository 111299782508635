import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import useApi from '@hooks/useApi';
import { Notification } from '@models/notifications/Notifications';

const useNotifications = () => {
    const api = useApi();

    // SWR fetcher to get notifications
    const fetcher = async (url: string) => {
        const data = await api.get<Notification[]>(url); // Expected return type is Notification[]
        return data;
    };

    // Fetch notifications using SWR
    const { data, error, mutate } = useSWR(Endpoints.notifications.inApp(), fetcher);

    // Function to mark a single notification as read
    const markAsRead = async (notificationId: string) => {
        try {
            await api.put(Endpoints.notifications.markAsRead(notificationId), { is_read: true }); // Corrected payload format
            mutate(); // Revalidate the notifications to refresh UI
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    // Function to mark all notifications as read
    const markAllAsRead = async () => {
        try {
            if (data) {
                await Promise.all(
                    data
                        .filter((notification) => !notification.is_read)
                        .map(
                            (notification) =>
                                api.put(Endpoints.notifications.markAsRead(notification.uuid), { is_read: true }), // Corrected payload format
                        ),
                );
                mutate(); // Revalidate the notifications to refresh UI
            }
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        }
    };

    // Function to mark a notification as unread
    const markAsUnread = async (notificationId: string) => {
        try {
            await api.put(Endpoints.notifications.markAsRead(notificationId), { is_read: false }); // Mark as unread
            mutate(); // Revalidate the notifications to refresh UI
        } catch (error) {
            console.error('Error marking notification as unread:', error);
        }
    };

    return {
        notifications: data,
        loading: !error && !data,
        error,
        mutate,
        markAsRead,
        markAllAsRead,
        markAsUnread,
    };
};

export default useNotifications;

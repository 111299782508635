import { defaultProps, IconProps } from '@components/icons/CustomIcons';

export const SolarSystemIcon = (props?: IconProps) => {
    const { height, width } = { ...defaultProps, ...props };

    return (
        <svg
            width={width}
            height={height}
            className="p-px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 20C4.55228 20 5 19.5523 5 19C5 18.4477 4.55228 18 4 18C3.44772 18 3 18.4477 3 19C3 19.5523 3.44772 20 4 20Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 3C17.5523 3 18 2.55228 18 2C18 1.44772 17.5523 1 17 1C16.4477 1 16 1.44772 16 2C16 2.55228 16.4477 3 17 3Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.7905 6.0523C11.8867 5.93217 10.9614 6.01889 10.0863 6.31338C8.79482 6.74799 7.68976 7.60927 6.95291 8.7555C6.21606 9.90172 5.89132 11.2646 6.03213 12.62C6.17293 13.9753 6.77088 15.2424 7.7276 16.2127C8.68432 17.1829 9.94283 17.7987 11.2961 17.9586C12.6493 18.1184 14.0166 17.8129 15.1731 17.0923C16.3296 16.3716 17.2064 15.2788 17.6591 13.9936C17.9751 13.0967 18.0714 12.1435 17.9481 11.2121"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.0523 11.2094C5.93217 12.1133 6.01889 13.0386 6.31338 13.9137C6.74799 15.2052 7.60927 16.3102 8.7555 17.0471C9.90172 17.7839 11.2646 18.1087 12.62 17.9679C13.9753 17.8271 15.2424 17.2291 16.2127 16.2724C17.1829 15.3157 17.7987 14.0572 17.9586 12.7039C18.1184 11.3507 17.8129 9.98337 17.0923 8.82688C16.3716 7.67039 15.2788 6.79364 13.9936 6.34089C13.0967 6.02494 12.1435 5.92859 11.2121 6.05194"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.1767 22.3142C10.0679 23.0152 12.1272 23.1867 14.1281 22.7922C16.7082 22.2834 19.0207 20.8671 20.6464 18.8C22.2721 16.7329 23.1034 14.1517 22.9897 11.5244C22.8952 9.34153 22.1536 7.24678 20.8741 5.5M1.3687 14.8241C0.880166 12.985 0.874041 11.0381 1.3687 9.17594C2.04386 6.6343 3.60724 4.41863 5.7755 2.93053C7.61522 1.66791 9.7871 0.999966 12 1"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SolarSystemIcon;

import { IconProps } from '@components/icons/CustomIcons';

export const HelpIcon = (props?: IconProps) => {
    const { width = 50, height = 50, className } = { ...props };
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_7824_6)">
                <path
                    d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25Z"
                    fill="url(#paint0_linear_7824_6)"
                />
                <path
                    d="M23.8045 30.7354C22.5053 30.7354 21.4536 31.818 21.4536 33.1171C21.4536 34.3855 22.4744 35.4991 23.8045 35.4991C25.1347 35.4991 26.1863 34.3855 26.1863 33.1171C26.1863 31.818 25.1036 30.7354 23.8045 30.7354Z"
                    fill="white"
                />
                <path
                    d="M24.2066 12.7012C20.0306 12.7012 18.1128 15.1759 18.1128 16.8463C18.1128 18.0527 19.1336 18.6095 19.9687 18.6095C21.6392 18.6095 20.9587 16.2275 24.1138 16.2275C25.6604 16.2275 26.8978 16.9081 26.8978 18.3311C26.8978 20.0014 25.1655 20.9603 24.1448 21.8264C23.2476 22.5996 22.0723 23.868 22.0723 26.5283C22.0723 28.1367 22.5054 28.6008 23.7735 28.6008C25.2892 28.6008 25.5986 27.9203 25.5986 27.3324C25.5986 25.7239 25.6295 24.796 27.3309 23.4658C28.166 22.8163 30.7953 20.7127 30.7953 17.8051C30.7953 14.8974 28.166 12.7012 24.2066 12.7012Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_7824_6"
                    x1="44"
                    y1="9"
                    x2="8"
                    y2="42.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4B1590" />
                    <stop offset="1" stopColor="#AD05B1" />
                </linearGradient>
                <clipPath id="clip0_7824_6">
                    <rect width="50" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default HelpIcon;

// hooks/useExtractedEmailFromURL.ts
import { useRouter } from 'next/router';

const useExtractedEmailFromURL = () => {
    const router = useRouter();
    const continueUrl = router.query.continueUrl ? decodeURIComponent(router.query.continueUrl as string) : '';

    const extractEmailFromUrl = (url: string): string | null => {
        // Updated regex to include the "+" character before the "@" symbol
        const emailRegex = /[\w\.-]+\+?[\w\.-]+@[\w\.-]+\.\w+/;
        const match = emailRegex.exec(url);
        return match ? match[0] : null;
    };

    return extractEmailFromUrl(continueUrl);
};

export default useExtractedEmailFromURL;

import { defaultProps, IconProps } from '@components/icons/CustomIcons';

export const CrosshairsIcon = (props?: IconProps) => {
    const { height, width } = { ...defaultProps, ...props };

    return (
        <svg
            width={width}
            height={height}
            className="flex items-center justify-center p-px"
            fill="none"
            viewBox="-1 -1 20 20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16.2633 8.15625H13.7812C13.3137 8.15625 12.9375 8.53242 12.9375 9C12.9375 9.46758 13.3137 9.84375 13.7812 9.84375H16.2633C15.8766 13.2082 13.2082 15.8766 9.84375 16.2633V13.7812C9.84375 13.3137 9.46758 12.9375 9 12.9375C8.53242 12.9375 8.15625 13.3137 8.15625 13.7812V16.2633C4.7918 15.8766 2.12344 13.2082 1.73672 9.84375H4.21875C4.68633 9.84375 5.0625 9.46758 5.0625 9C5.0625 8.53242 4.68633 8.15625 4.21875 8.15625H1.73672C2.12344 4.7918 4.7918 2.12344 8.15625 1.73672V4.21875C8.15625 4.68633 8.53242 5.0625 9 5.0625C9.46758 5.0625 9.84375 4.68633 9.84375 4.21875V1.73672C13.2082 2.12344 15.8766 4.7918 16.2633 8.15625ZM0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9Z"
                fill="#C2C2C2"
            />
        </svg>
    );
};

export default CrosshairsIcon;

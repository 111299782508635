import Link from 'next/link';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Twitter from '@mui/icons-material/Twitter';
import YouTube from '@mui/icons-material/YouTube';
import useAuth from '@hooks/useAuth';

type FooterLink = {
    name: string;
    url: string;
    external?: boolean;
};

const productLinks: FooterLink[] = [
    { name: 'Pricing', url: 'https://pluto.bio/pricing' },
    { name: 'Help Center', url: 'http://help.pluto.bio/', external: true },
    // Log Out / Log In link will be conditionally rendered
];

const companyLinks: FooterLink[] = [
    { name: 'About', url: 'https://pluto.bio/about' },
    { name: 'Blog', url: 'https://pluto.bio/resources' },
    { name: 'Careers', url: 'https://www.builtincolorado.com/company/pluto-biosciences/jobs', external: true },
    { name: 'Partnerships', url: '/about#partnerships' },
    { name: 'News', url: '/news' },
    { name: 'Press Kit', url: 'https://brandfolder.com/pluto-bio/pluto-press-kit', external: true },
];

const resourcesLinks: FooterLink[] = [
    { name: 'Contact', url: 'https://pluto.bio/contact' },
    { name: 'Get a Quote', url: 'https://pluto.bio/get-info' },
    { name: 'Developers / API', url: 'https://docs.pluto.bio', external: true },
    { name: 'Security', url: 'https://pluto.bio/security' },
    { name: 'Trust Center', url: 'https://trust.pluto.bio', external: true },
    { name: 'Privacy', url: 'https://pluto.bio/privacy-policy' },
    { name: 'Terms', url: 'https://pluto.bio/terms-of-use' },
];

const Footer = () => {
    const { isLoggedIn: loggedIn } = useAuth();

    const renderLink = (link: FooterLink, key: string) => {
        if (link.external) {
            return (
                <a
                    key={key}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mb-4 block text-xs text-white hover:text-indigo-300 sm:text-sm"
                >
                    {link.name}
                </a>
            );
        } else {
            return (
                <Link
                    key={key}
                    href={link.url}
                    className="mb-4 block cursor-pointer text-xs text-white hover:text-indigo-300 hover:opacity-70 sm:text-sm"
                >
                    {link.name}
                </Link>
            );
        }
    };

    return (
        <>
            <footer className="bg-gray-900 px-4 py-16 text-white">
                <div className="mx-auto flex max-w-6xl flex-wrap px-2 sm:px-4 md:flex-nowrap lg:px-8">
                    <div className="order-4 flex flex-grow flex-col justify-between opacity-80 md:order-none md:p-0">
                        <div className="flex flex-col items-start">
                            <img
                                className="-mt-2 mb-3 mr-4 h-8 w-auto"
                                src="https://cdn.bfldr.com/2Q1IPX6I/at/7ckgrhj7pfwrcsh7hr427fj/pluto-inline-logo"
                                alt="Pluto logo"
                                width="83"
                                loading="lazy"
                            />
                            <Link
                                href="mailto:hello@pluto.bio"
                                className="mb-1 cursor-pointer text-xs font-normal text-white hover:text-indigo-300 hover:opacity-70 sm:text-sm"
                            >
                                hello@pluto.bio
                            </Link>
                            <Link
                                href="tel:+17207128898"
                                className="cursor-pointer text-xs font-normal text-white hover:text-indigo-300 hover:opacity-70 sm:text-sm"
                            >
                                (720) 712-8898
                            </Link>
                        </div>
                        <div>
                            <div className="mb-2 flex">
                                <a
                                    className="mr-2 text-white opacity-80 hover:text-indigo-300"
                                    href="https://www.linkedin.com/company/pluto-biosciences/"
                                    target="_blank"
                                    rel="nofollow noreferrer"
                                    aria-label="Pluto on LinkedIn"
                                >
                                    <LinkedIn fontSize="small" />
                                </a>
                                <a
                                    className="mr-2 text-white opacity-80 hover:text-indigo-300"
                                    href="https://twitter.com/tryplutobio"
                                    target="_blank"
                                    rel="nofollow noreferrer"
                                    aria-label="Pluto on Twitter"
                                >
                                    <Twitter fontSize="small" />
                                </a>
                                <a
                                    className="text-white opacity-80 hover:text-indigo-300"
                                    href="https://www.youtube.com/channel/UCDCnL58lbfFF8oA1YyXH28A"
                                    target="_blank"
                                    rel="nofollow noreferrer"
                                    aria-label="Pluto on Youtube"
                                >
                                    <YouTube fontSize="small" />
                                </a>
                            </div>
                            <p className="text-xs sm:text-sm">&copy; {new Date().getFullYear()} Pluto Bioinformatics</p>
                        </div>
                    </div>
                    <div className={'flex w-1/3 flex-col pb-8 opacity-80 md:w-auto md:p-0 md:pr-24'}>
                        <p className="mb-4 text-xs text-white sm:text-sm">Product</p>
                        {productLinks.map((link, index) => renderLink(link, `product-${index}`))}
                        {loggedIn
                            ? renderLink({ name: 'Log Out', url: '/logout' }, 'logout')
                            : renderLink({ name: 'Log In', url: '/login' }, 'login')}
                    </div>
                    <div className={'flex w-1/3 flex-col justify-start pb-8 opacity-80 md:w-auto md:p-0 md:pr-24'}>
                        <p className="mb-4 text-xs text-white sm:text-sm">Company</p>
                        {companyLinks.map((link, index) => renderLink(link, `company-${index}`))}
                    </div>
                    <div className={'flex w-1/3 flex-col justify-start opacity-80 sm:pb-8 md:w-auto md:px-0 md:pb-0'}>
                        <p className="mb-4 text-xs text-white sm:text-sm">Resources</p>
                        {resourcesLinks.map((link, index) => renderLink(link, `resource-${index}`))}
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;

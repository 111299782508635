import { Team } from '@models/Team';
import { SimpleUser } from '@models/User';
import { randomEnum } from '@util/EnumUtil';
import { PlutoIdentifiable, ShareLevel } from '@api/ApiTypes';
import { UserPermissionObject } from '@models/Permission';
import { isDefined } from '@util/TypeGuards';

export enum ProjectType {
    shareable = 'shareable',
    team = 'team',
}

export interface Project extends UserPermissionObject, PlutoIdentifiable {
    uuid: string;
    pluto_id: string;
    name: string;
    funding_source_id?: string | null;
    team?: Team | null;
    is_private?: boolean;
    is_archived?: boolean;
    share_level?: ShareLevel;
    color?: ProjectColor;
    experiments_count: number;
    description?: string;
    external_url?: string | null;
    external_url_display_name?: string | null;
    owner?: SimpleUser | null;
}

export interface CreateProjectParams {
    name: string;

    /** Funding source has been renamed to Program, but is still called FundingSource in the API */
    funding_source_id?: string | null;
    description?: string | null;
    team_id?: string | null;
    color?: ProjectColor;
}

export type UpdateProjectParams = CreateProjectParams &
    Pick<Project, 'uuid' | 'external_url' | 'external_url_display_name'>;

export const isUpdateProjectParams = (
    values: UpdateProjectParams | CreateProjectParams,
): values is UpdateProjectParams => {
    return isDefined((values as UpdateProjectParams)?.uuid);
};

export enum ProjectColor {
    blue,
    blueGray,
    violet,
    cyan,
    lightGreen,
    rose,
    gold,
}

export function getRandomProjectColor(): ProjectColor {
    return randomEnum(ProjectColor);
}

export const allProjectColors: ProjectColor[] = Object.values(ProjectColor) as ProjectColor[];

export interface ProjectColorInfo {
    text: string;
    bg: string;
    fill: string;
    stroke: string;
    hoverBg: string;
    experimentHoverBg: string;
    borderColor: string;
}

export function getProjectColorClasses(color?: ProjectColor | null): ProjectColorInfo {
    switch (color) {
        case ProjectColor.blue:
            return {
                text: 'text-primary',
                bg: 'bg-indigo-200',
                fill: 'fill-indigo-200',
                stroke: 'stroke-indigo-200',
                hoverBg: 'hover:bg-indigo-300',
                experimentHoverBg: 'hover:bg-indigo-100',
                borderColor: 'border-indigo-200',
            };
        case ProjectColor.blueGray:
            return {
                text: 'text-blueGray-600',
                bg: 'bg-blueGray-200',
                fill: 'fill-blueGray-200',
                stroke: 'stroke-blueGray-200',
                hoverBg: 'hover:bg-blueGray-300',
                experimentHoverBg: 'hover:bg-blueGray-100',
                borderColor: 'border-blueGray-200',
            };
        case ProjectColor.violet:
            return {
                text: 'text-violet-800',
                bg: 'bg-violet-200',
                fill: 'fill-violet-200',
                stroke: 'stroke-violet-200',
                hoverBg: 'hover:bg-violet-300',
                experimentHoverBg: 'hover:bg-violet-100',
                borderColor: 'border-violet-200',
            };
        case ProjectColor.cyan:
            return {
                text: 'text-cyan-800',
                bg: 'bg-cyan-100',
                fill: 'fill-cyan-100',
                stroke: 'stroke-cyan-100',
                hoverBg: 'hover:bg-cyan-200',
                experimentHoverBg: 'hover:bg-cyan-100',
                borderColor: 'border-cyan-100',
            };
        case ProjectColor.lightGreen:
            return {
                text: 'text-emerald-800',
                bg: 'bg-emerald-100',
                fill: 'fill-emerald-100',
                stroke: 'stroke-emerald-100',
                hoverBg: 'hover:bg-emerald-200',
                experimentHoverBg: 'hover:bg-emerald-100',
                borderColor: 'border:bg-emerald-100',
            };
        case ProjectColor.rose:
            return {
                text: 'text-rose-900',
                bg: 'bg-rose-100',
                fill: 'fill-rose-100',
                stroke: 'stroke-rose-100',
                hoverBg: 'hover:bg-rose-200',
                experimentHoverBg: 'hover:bg-rose-100',
                borderColor: 'border-rose-100',
            };
        case ProjectColor.gold:
            return {
                text: 'text-yellow-800',
                bg: 'bg-yellow-100',
                fill: 'fill-yellow-100',
                stroke: 'stroke-yellow-100',
                hoverBg: 'hover:bg-yellow-200',
                experimentHoverBg: 'hover:bg-yellow-100',
                borderColor: 'border-yellow-100',
            };
        default:
            return {
                text: 'text-primary',
                bg: 'bg-indigo-200',
                fill: 'fill-indigo-200',
                stroke: 'stroke-indigo-200',
                hoverBg: 'hover:bg-indigo-300',
                experimentHoverBg: 'hover:bg-indigo-100',
                borderColor: 'border-indigo-200',
            };
    }
}

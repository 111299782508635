import { useLabSpaceContext } from '@contexts/LabSpaceContext';
import ExperimentSharingDialog from '@components/sharing/ExperimentSharingDialog';

const LabspaceExperimentSharingDialog = () => {
    const { experimentSharingModal, updateExperimentSharingModal } = useLabSpaceContext();
    const { item: experiment, open } = experimentSharingModal;

    const handleClose = () => {
        updateExperimentSharingModal({ open: false });
    };

    return <ExperimentSharingDialog experiment={experiment} onClose={() => handleClose()} open={open} />;
};

export default LabspaceExperimentSharingDialog;

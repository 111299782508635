import DefaultLayout, { DefaultLayoutProps } from '@layouts/DefaultLayout';
import { PropsWithChildren, ReactNode, useEffect } from 'react';
import DashboardNavigation from '@components/layout/DashboardNavigation';
import cn from 'classnames';
import LoginForm from '@components/LoginForm';
import Card from '@components/Card';
import ProjectArchiveConfirmModal from '@components/projects/ProjectArchiveConfirmModal';
import useAuth from '@hooks/useAuth';
import LoadingMessage from '@components/LoadingMessage';
import ProjectSharingDialog from '@components/sharing/ProjectSharingDialog';
import { LabSpaceContextProvider } from '@contexts/LabSpaceContext';
import LabspaceExperimentSharingDialog from '@components/sharing/LabspaceExperimentSharingDialog';
import TrialBanner from '@components/trial/TrialBanner';
import { useTrialContext } from '@contexts/TrialContext';
import { useRouter } from 'next/router';
import Banner from '@components/banner/Banner';

const DefaultAuthComponent = () => {
    return (
        <DefaultLayout maxWidth="lg">
            <Card>
                <h3 className="mb-4 text-center text-3xl font-semibold tracking-tight">
                    {"There's so much more to explore on Pluto!"}
                </h3>
                <p className="mb-8 text-center text-default">Log in to your account to view this page</p>
                <LoginForm />
            </Card>
        </DefaultLayout>
    );
};

export type DashboardLayoutProps = PropsWithChildren<
    DefaultLayoutProps & { authRequired?: boolean; authComponent?: ReactNode; fixedViewport?: boolean }
>;
const DashboardLayout = ({
    children,
    className,
    container = false,
    noMargin,
    authRequired = false,
    authComponent = <DefaultAuthComponent />,
    ...props
}: DashboardLayoutProps) => {
    const router = useRouter();
    const { isLoggedIn, authReady } = useAuth();
    const { trialExpired } = useTrialContext();
    const hasAccess = isLoggedIn || !authRequired;
    let $body: ReactNode = null;
    if (hasAccess) {
        $body = children;
    } else if (!authReady) {
        $body = <LoadingMessage />;
    } else if (authReady && !isLoggedIn) {
        $body = authComponent;
    }

    useEffect(() => {
        if (trialExpired && authReady && isLoggedIn) {
            router.push('https://pluto.bio/get-info');
        }
    }, [authReady, isLoggedIn, trialExpired]);

    if (trialExpired) {
        return null;
    }

    return (
        <DefaultLayout
            className={cn(className, 'max-w-screen min-h-screen')}
            container={container}
            {...props}
            noMargin={noMargin}
        >
            <LabSpaceContextProvider>
                <Banner />
                <TrialBanner innerClassName="" />
                <div className="relative">
                    <div className="flex">
                        <div className="flex-none md:w-[60px]">
                            <DashboardNavigation />
                        </div>
                        <div className="min-w-screen flex-1 min-w-[calc(100vw-60px)]">{$body}</div>
                    </div>
                </div>
                <ProjectArchiveConfirmModal />
                <LabspaceExperimentSharingDialog />
                <ProjectSharingDialog />
            </LabSpaceContextProvider>
        </DefaultLayout>
    );
};

export default DashboardLayout;

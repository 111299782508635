import { Dialog, DialogContent } from '@mui/material';
import { useLabSpaceContext } from '@contexts/LabSpaceContext';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import NotificationsView from '@components/notifications/NotificationsView';

const NotificationsDialog = () => {
    const { NotificationsModalOpen, setNotificationsModalOpen } = useLabSpaceContext();
    return (
        <Dialog open={NotificationsModalOpen} onClose={() => setNotificationsModalOpen(false)} maxWidth="sm" fullWidth>
            <DialogCloseButton onClose={() => setNotificationsModalOpen(false)} />
            <PlutoDialogTitle title="Notifications" />
            <DialogContent>
                <NotificationsView />
            </DialogContent>
        </Dialog>
    );
};

export default NotificationsDialog;

import { Dialog, DialogContent } from '@mui/material';
import { useLabSpaceContext } from '@contexts/LabSpaceContext';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import PendingInvitesView from '@components/PendingInvitesView';

const PendingInvitesDialog = () => {
    const { pendingInvitesModalOpen, setPendingInvitesModalOpen } = useLabSpaceContext();
    return (
        <Dialog
            open={pendingInvitesModalOpen}
            onClose={() => setPendingInvitesModalOpen(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogCloseButton onClose={() => setPendingInvitesModalOpen(false)} />
            <PlutoDialogTitle title="Invitations" />
            <DialogContent>
                <PendingInvitesView />
            </DialogContent>
        </Dialog>
    );
};

export default PendingInvitesDialog;

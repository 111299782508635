import { Invite } from '@models/Invitation';
import cn from 'classnames';
import Button from '@components/Button';
import LinkButton from './ButtonLink';
import { useLabSpaceContext } from '@contexts/LabSpaceContext';

const startsWithVowel = new RegExp(/^[aeiouyAEIOUY]/);
type Props = { invite: Invite; className?: string; handleAccept: (invite: Invite) => void; loading?: boolean };
const PendingInviteItem = ({ invite, className, handleAccept, loading }: Props) => {
    const { setPendingInvitesModalOpen } = useLabSpaceContext();

    let typeJoiner = 'a';
    if (invite.invite_type.match(startsWithVowel)) {
        typeJoiner = 'an';
    }

    const roleJoiner = invite.role_type.match(startsWithVowel) ? 'an' : 'a';

    let targetUrl: string | null = null;
    switch (invite.invite_type) {
        case 'experiment':
            targetUrl = `/experiments/${invite.invite_type_id}`;
            break;
        case 'project':
            targetUrl = `/projects/${invite.invite_type_id}`;
            break;
        case 'organization':
            targetUrl = '/team';
            break;
        case 'team':
            targetUrl = `/team/${invite.invite_type_id}`;
            break;
        default:
            break;
    }

    return (
        <div className={cn(className, 'flex justify-between space-x-4')}>
            <div>
                <div>
                    <span className="font-semibold">{invite.from_email}</span> invited you to {typeJoiner}{' '}
                    {invite.invite_type} as&nbsp;{roleJoiner}&nbsp;
                    <span className="font-semibold">{invite.role_type}</span>
                </div>
            </div>
            <div className="flex flex-shrink-0 items-start justify-center">
                <span>
                    {invite.is_active && (
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            disabled={!invite.is_active || loading}
                            loading={loading}
                            onClick={() => {
                                handleAccept(invite);
                            }}
                        >
                            {invite.is_active ? 'Accept' : 'Accepted'}
                        </Button>
                    )}
                    {targetUrl && !invite.is_active && (
                        <LinkButton
                            href={targetUrl}
                            variant="outlined"
                            color="primary"
                            size="small"
                            loading={loading}
                            disabled={loading}
                            onClick={() => {
                                setPendingInvitesModalOpen(false);
                            }}
                        >
                            <span className="">View</span>
                        </LinkButton>
                    )}
                </span>
            </div>
        </div>
    );
};

export default PendingInviteItem;

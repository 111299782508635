import { AvatarCircle, AvatarCircleProps } from '@components/AvatarCircle';
import { getFullName } from '@models/User';
import useAuth from '@hooks/useAuth';

type UserAvatarProps = Pick<
    AvatarCircleProps,
    'size' | 'widthClass' | 'heightClass' | 'diameter' | 'title' | 'disableTooltip'
>;

const UserAvatar = (props: UserAvatarProps) => {
    const { user } = useAuth();

    if (!user) {
        return null;
    }

    return <AvatarCircle imageUrl={user.avatar_url} title={getFullName(user)} userId={user.uuid} {...props} />;
};

export default UserAvatar;

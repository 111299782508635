import { PermissionObjectName, RoleLevel } from '@models/Permission';
import { getFullName, SharingMember } from '@models/User';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TimeoutValue } from '@util/ObjectUtil';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { CircularProgress, IconButton, List, ListItem, MenuItem, Select } from '@mui/material';
import { AvatarCircle } from '@components/AvatarCircle';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Check } from '@mui/icons-material';
import Button from '@components/Button';
import { isBlank } from '@util/StringUtil';
import ConfirmModal from '@components/ConfirmModal';
import cn from 'classnames';

export type EditMembersProps = {
    item: { uuid: string; name?: string };
    itemType: PermissionObjectName;
    roleLevels?: RoleLevel[];
    removeMember: (member: SharingMember) => Promise<void>;
    canEditRoles?: boolean;
    canRemoveMembers?: boolean;
    members: SharingMember[] | undefined;
    saveMember: (member: SharingMember) => Promise<void>;
    canTransferOwnership?: boolean;
    onOwnershipChanged?: () => Promise<void>;
    title?: string;
    titleClassName?: string;
};
export const EditSharingMembersList = ({
    canEditRoles,
    canRemoveMembers,
    canTransferOwnership = false,
    itemType,
    members: _members,
    onOwnershipChanged,
    removeMember,
    roleLevels,
    saveMember,
    title,
    titleClassName,
}: EditMembersProps) => {
    const [confirmRemoveId, setConfirmRemoveId] = useState<string | null>(null);
    const [membersSaving, setMembersSaving] = useState<Record<string, boolean | undefined>>({});
    const memberSuccessTimeoutsRef = useRef<Record<string, TimeoutValue | undefined>>({});
    const [memberSuccessMap, setMemberSuccessMap] = useState<Record<string, boolean>>({});
    const [ownershipTransferMember, setOwnershipTransferMember] = useState<SharingMember | null>(null);
    const [transferOwnershipLoading, setTransferOwnershipLoading] = useState(false);
    const [admins, setAdmins] = useState<SharingMember[]>([]);
    useEffect(() => {
        return () => {
            Object.values(memberSuccessTimeoutsRef.current ?? {}).forEach(clearTimeout);
        };
    }, []);

    const members = useMemo(() => {
        if (!_members) {
            return undefined;
        }

        const newAdmins = [..._members].filter((member) => !member.role_shortname);
        setAdmins(newAdmins);
        return [..._members].filter((member) => !!member.role_shortname);
    }, [_members]);

    const confirmRemoveMember = async (member: SharingMember) => {
        await removeMember(member);
        setConfirmRemoveId(null);
    };

    const handleTransferOwnership = async (newOwner: SharingMember | null) => {
        if (!newOwner) {
            return;
        }
        setTransferOwnershipLoading(true);
        await saveMemberRole(newOwner, RoleLevel.owner);
        await onOwnershipChanged?.();
        setTransferOwnershipLoading(false);
        setOwnershipTransferMember(null);
    };

    const handleRoleChanged = (member: SharingMember, role: RoleLevel) => {
        if (role === RoleLevel.owner) {
            setOwnershipTransferMember(member);
        } else {
            setOwnershipTransferMember(null);
            saveMemberRole(member, role);
        }
    };

    const saveMemberRole = async (member: SharingMember, role: RoleLevel) => {
        setMembersSaving((current) => ({ ...current, [member.uuid]: true }));

        await saveMember({ ...member, role_shortname: role });
        setMembersSaving({ ...membersSaving, [member.uuid]: false });
        setMemberSuccessMap((current) => ({ ...current, [member.uuid]: true }));
        if (memberSuccessTimeoutsRef.current[member.uuid]) {
            clearTimeout(memberSuccessTimeoutsRef.current[member.uuid]);
        }

        memberSuccessTimeoutsRef.current[member.uuid] = setTimeout(() => {
            memberSuccessTimeoutsRef.current[member.uuid] = undefined;
            setMemberSuccessMap((current) => ({ ...current, [member.uuid]: false }));
        }, 3500);
    };

    const parsedType = itemType === 'biomarkerset' ? 'list' : itemType;
    const titleString = title ?? `Shared on ${parsedType}`;

    return (
        <>
            <p className={cn(titleClassName, 'text-lg font-semibold tracking-tight text-dark')}>
                {titleString} <span className="text-grey font-normal">({members?.length ?? 0})</span>
            </p>
            <Flipper flipKey={(members ?? []).map((m) => m.uuid).join('')}>
                <List>
                    {(members ?? []).map((member) => {
                        const roleValue = member.role_shortname;
                        const roleChanged = roleValue !== member.role_shortname;
                        const saving = membersSaving[member.uuid] ?? false;
                        const saveSuccess = memberSuccessMap[member.uuid] ?? false;
                        if (!roleValue) return <></>;
                        return (
                            <Flipped key={`user_${member.uuid}`} flipId={`user_${member.uuid}`}>
                                <ListItem disableGutters>
                                    {confirmRemoveId !== member.uuid && (
                                        <>
                                            <AvatarCircle
                                                imageUrl={member.avatar_url}
                                                size={'xs'}
                                                classes="mr-2"
                                                userId={member.uuid}
                                                title={getFullName(member)}
                                            />
                                            <div className="grid w-full grid-cols-2 items-center">
                                                <div className="flex flex-col pr-2">
                                                    <span className="col-span-1 font-semibold text-dark">
                                                        {getFullName(member)}
                                                    </span>
                                                    {!isBlank(member.email) && (
                                                        <span className="break-all">{member.email}</span>
                                                    )}
                                                </div>

                                                <div className="flex items-center justify-between">
                                                    {!canEditRoles ||
                                                        (member.role_shortname === RoleLevel.owner && (
                                                            <span className="pl-4 text-sm text-default">
                                                                {member.role_displayname}
                                                            </span>
                                                        ))}
                                                    {canEditRoles && member.role_shortname !== RoleLevel.owner && (
                                                        <Select
                                                            IconComponent={KeyboardArrowDownRoundedIcon}
                                                            margin="dense"
                                                            onChange={(e) =>
                                                                handleRoleChanged(member, e.target.value as RoleLevel)
                                                            }
                                                            value={roleValue}
                                                            variant="outlined"
                                                            fullWidth
                                                            className="mr-2"
                                                        >
                                                            {canTransferOwnership &&
                                                                !roleLevels?.includes(RoleLevel.owner) && (
                                                                    <MenuItem value={RoleLevel.owner}>
                                                                        <div className="flex max-w-sm flex-row items-center justify-start whitespace-normal capitalize sm:max-w-lg">
                                                                            Owner
                                                                        </div>
                                                                    </MenuItem>
                                                                )}
                                                            {roleLevels?.map((roleLevel) => (
                                                                <MenuItem key={roleLevel} value={roleLevel}>
                                                                    <div className="flex max-w-sm flex-row items-center justify-start whitespace-normal capitalize sm:max-w-lg">
                                                                        {roleLevel}
                                                                    </div>
                                                                </MenuItem>
                                                            ))}
                                                            {!roleLevels?.includes(member.role_shortname) && (
                                                                <MenuItem
                                                                    key={member.role_shortname}
                                                                    value={member.role_shortname}
                                                                    disabled
                                                                >
                                                                    <div className="flex max-w-sm flex-row items-center justify-start whitespace-normal capitalize sm:max-w-lg">
                                                                        {member.role_displayname}
                                                                    </div>
                                                                </MenuItem>
                                                            )}
                                                        </Select>
                                                    )}
                                                    {!saveSuccess &&
                                                        !saving &&
                                                        canRemoveMembers &&
                                                        !roleChanged &&
                                                        member.role_shortname !== RoleLevel.owner && (
                                                            <IconButton
                                                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                                                onClick={() => setConfirmRemoveId(member.uuid)}
                                                                size="small"
                                                            >
                                                                <DeleteOutlineRoundedIcon
                                                                    fontSize="small"
                                                                    color="error"
                                                                />
                                                            </IconButton>
                                                        )}
                                                    {saving && <CircularProgress variant="indeterminate" size={20} />}
                                                    {!saving && saveSuccess && (
                                                        <div className="flex">
                                                            <Check className="text-success" />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {confirmRemoveId === member.uuid && (
                                        <div className="flex w-full items-center justify-between rounded-md bg-error px-4 py-2">
                                            <span className="text-sm text-dark">Remove {getFullName(member)}?</span>
                                            <div className="space-x-1">
                                                <Button
                                                    variant="text"
                                                    size="small"
                                                    onClick={() => confirmRemoveMember(member)}
                                                >
                                                    <span className="text-error">Remove</span>
                                                </Button>
                                                <Button
                                                    onClick={() => setConfirmRemoveId(null)}
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </ListItem>
                            </Flipped>
                        );
                    })}
                </List>
            </Flipper>
            {!!admins.length ? (
                <List>
                    <p className={cn(titleClassName, 'text-lg font-semibold tracking-tight text-dark')}>
                        Organization admins that can also view this {parsedType}{' '}
                        <span className="text-grey font-normal">({admins.length})</span>
                    </p>
                    {admins.map((admin) => (
                        <ListItem disableGutters key={admin.uuid}>
                            <AvatarCircle
                                imageUrl={admin.avatar_url}
                                size={'xs'}
                                classes="mr-2"
                                userId={admin.uuid}
                                title={getFullName(admin)}
                            />
                            <div className="grid w-full grid-cols-2 items-center">
                                <div className="flex flex-col pr-2">
                                    <span className="col-span-1 font-semibold text-dark">{getFullName(admin)}</span>
                                    {!isBlank(admin.email) && <span className="break-all">{admin.email}</span>}
                                </div>
                            </div>
                        </ListItem>
                    ))}
                </List>
            ) : null}
            <ConfirmModal
                key="sharing-confirm-modal"
                open={!!ownershipTransferMember && canTransferOwnership}
                onConfirm={() => handleTransferOwnership(ownershipTransferMember)}
                onCancel={() => setOwnershipTransferMember(null)}
                title="Transfer ownership"
                confirmText={transferOwnershipLoading ? 'Saving...' : 'Yes, transfer ownership'}
                cancelText="No, never mind"
                confirmLoading={transferOwnershipLoading}
                message={
                    ownershipTransferMember && (
                        <div>
                            Are you sure you want to give{' '}
                            <span className="font-semibold">{getFullName(ownershipTransferMember)}</span> ownership of
                            this {itemType}? There can only be one owner, so your role will be updated to{' '}
                            <span className="italic">Editor</span> if you proceed.
                        </div>
                    )
                }
            ></ConfirmModal>
        </>
    );
};

export default EditSharingMembersList;

import cn from 'classnames';
import { Tooltip, TooltipProps } from '@mui/material';
import ButtonGroupItemView from '@components/ButtonGroupItemView';
import { ReactNode, useCallback } from 'react';
import ConditionalWrapper from './ConditionalWrapper';

export type ButtonGroupItemID = number | string | any;
export type ButtonGroupItem<T extends ButtonGroupItemID = ButtonGroupItemID> = {
    disabled?: boolean;
    label: ReactNode;
    onDark?: boolean;
    tooltip?: Pick<TooltipProps, 'arrow' | 'placement' | 'title'>;
    value: T;
};

export type Props<T extends ButtonGroupItemID> = {
    className?: string;
    cyId?: string;
    disabled?: boolean;
    dividerIcon?: ReactNode;
    groupedDividerIcon?: ReactNode;
    groupedItems?: ButtonGroupItem<T>[];
    itemClassName?: string;
    items: ButtonGroupItem<T>[];
    onChange: (value: T | null) => void;
    onDark?: boolean;
    selectedItemClassName?: string;
    toggle?: boolean;
    value?: T | null;
};

function ButtonGroup<P extends ButtonGroupItemID>({
    className,
    cyId,
    disabled,
    dividerIcon,
    groupedDividerIcon,
    groupedItems,
    itemClassName,
    items,
    onChange,
    onDark = false,
    selectedItemClassName,
    toggle = true,
    value,
}: Props<P>) {
    const handleClick = (item: ButtonGroupItem<P>) => {
        if (item.disabled || disabled) {
            return;
        }
        if (value === item.value && toggle) {
            onChange(null);
        } else if (value !== item.value) {
            onChange(item.value);
        }
    };

    const renderDividerIcon = useCallback((children, grouped) => {
        return (
            <>
                {children}
                {grouped ? groupedDividerIcon : dividerIcon}
            </>
        );
    }, []);

    const renderItem = (item: ButtonGroupItem, i: number, grouped = false) => {
        const selected = item.value === value;
        const isNotLastItem =
            Boolean(groupedItems) && !grouped ? true : (grouped ? groupedItems ?? [] : items).length - 1 !== i;
        const $item = (
            <ButtonGroupItemView
                item={item}
                key={`${item.value}_${i}`}
                onClick={() => handleClick(item)}
                onDark={onDark}
                disabled={disabled}
                selected={selected}
                className={cn(
                    'my-1 px-1',
                    {
                        'bg-primary py-1': grouped,
                    },
                    itemClassName,
                )}
                selectedItemClassName={selected ? selectedItemClassName : ''}
            />
        );
        // Note: the order of these properties seems to matter.
        // If the `item.tooltip` properties are spread (`{...item.tooltip}`) _before_ the `key` property is set,
        // a runtime error in development mode is thrown saying that `className` can not be read from undefined.
        return (
            <ConditionalWrapper
                key={`item_${item.value}_${i}`}
                condition={Boolean(dividerIcon) && isNotLastItem}
                wrapper={(children) => renderDividerIcon(children, grouped)}
            >
                {item.tooltip ? (
                    <Tooltip key={`item_${item.value}_${i}`} {...item.tooltip}>
                        <span className="mr-1 flex-1 last:mr-0">{$item}</span>
                    </Tooltip>
                ) : (
                    $item
                )}
            </ConditionalWrapper>
        );
    };

    return (
        <div
            data-cy={cyId}
            className={cn(
                'flex w-full flex-1 flex-row rounded-3xl pl-1',
                className,
                onDark ? 'bg-transparent md:bg-gray-900' : 'bg-indigo-50',
                {
                    'pr-1': !Boolean(groupedItems),
                },
            )}
            role="group"
        >
            {items.map((item, i) => renderItem(item, i))}
            {groupedItems && (
                <div className="flex flex-row items-center rounded-3xl bg-indigo-50 px-1">
                    {groupedItems?.map((item, i) => renderItem(item, i, true))}
                </div>
            )}
        </div>
    );
}

export default ButtonGroup;

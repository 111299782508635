import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Button from '@components/Button';
import { ReactNode } from 'react';
import useBreakpoints from '@hooks/useBreakpoints';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import { Alert } from '@mui/material';
import cn from 'classnames';

export type Props = {
    cancelText?: string;
    centerMessage?: boolean;
    confirmClassname?: string;
    confirmIcon?: ReactNode;
    confirmLoading?: boolean;
    confirmText?: string;
    error?: ReactNode;
    icon?: ReactNode;
    message?: string | ReactNode;
    onCancel: () => void;
    onConfirm: () => void;
    open?: boolean;
    title?: string;
};
const ConfirmModal = ({
    cancelText = 'Cancel',
    centerMessage = false,
    confirmClassname,
    confirmIcon,
    confirmLoading = false,
    confirmText = 'Confirm',
    error,
    icon,
    message,
    onCancel,
    onConfirm,
    open = false,
    title,
}: Props) => {
    const { isSmAndDown } = useBreakpoints();
    const handleConfirm = () => {
        onConfirm();
    };

    return (
        <Dialog open={open} maxWidth="sm">
            <DialogCloseButton onClose={() => onCancel()} />
            {title && <PlutoDialogTitle iconStart={icon} iconEnd={icon} title={title} />}
            {message && (
                <DialogContent className="mb-4 space-y-4">
                    {error && <Alert severity="error">{error}</Alert>}
                    <div
                        className={cn('text-base', {
                            'text-center': centerMessage,
                        })}
                    >
                        {message}
                    </div>
                </DialogContent>
            )}
            <DialogActions>
                <Button variant={isSmAndDown ? 'outlined' : 'text'} onClick={onCancel} fullWidth={isSmAndDown}>
                    {cancelText}
                </Button>
                <Button
                    color="primary"
                    onClick={handleConfirm}
                    variant="contained"
                    loading={confirmLoading}
                    disabled={confirmLoading}
                    fullWidth={isSmAndDown}
                    className={confirmClassname}
                >
                    {confirmIcon}
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmModal;

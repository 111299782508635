import { IconProps } from '@components/icons/CustomIcons';

export const HelpCircleIcon = (props: IconProps) => (
    <svg
        width={props.width ?? 18}
        height={props.height ?? 18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
            stroke="currentColor"
            strokeWidth={props.strokeWidth ?? '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.81738 6.75043C6.99371 6.24918 7.34175 5.82651 7.79985 5.55728C8.25795 5.28805 8.79655 5.18963 9.32027 5.27946C9.84398 5.36929 10.319 5.64157 10.6612 6.04807C11.0034 6.45458 11.1907 6.96907 11.1899 7.50043C11.1899 9.00043 8.93988 9.75043 8.93988 9.75043"
            stroke="currentColor"
            strokeWidth={props.strokeWidth ?? '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M9 12.75H9.0075" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

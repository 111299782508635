import { useRouter } from 'next/router';

const useInviteValidation = () => {
    const router = useRouter();
    const continueUrl = router.query.continueUrl ? decodeURIComponent(router.query.continueUrl as string) : '';

    const hasInviteID = (): boolean => {
        const inviteIdRegex = /invite_id=[\w-]+/;
        return inviteIdRegex.test(continueUrl);
    };

    const isManualInvite = (): boolean => {
        return router.query.manual === 'true';
    };

    return hasInviteID() || isManualInvite();
};

export default useInviteValidation;

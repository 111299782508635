import { CreateProjectParams, Project, UpdateProjectParams } from '@models/Project';
import Endpoints from '@services/Endpoints';
import useApi from '@hooks/useApi';
import useMatchMutate from '@hooks/useMatchMutate';
import { blankToNull } from '@util/StringUtil';
import { PaginationResponse } from '@services/EndpointUtil';

type ProjectProps = { project?: Project | null };
const useProjectApi = () => {
    const api = useApi();
    const mutations = useMatchMutate();

    const revalidateLabProjects = async ({ project }: ProjectProps = {}) => {
        const mutator = async (current: PaginationResponse<Project> | undefined) => {
            if (!current) {
                return current;
            }
            const items = (current?.items ?? []).map((proj) => {
                return { ...proj, is_archived: proj.uuid === project?.uuid ? true : proj.is_archived };
            });

            return { ...current, items };
        };
        await mutations.pathEqualsIgnoreQueryMutate(Endpoints.lab.projects(), mutator, {
            revalidate: true,
            populateCache: true,
        });
        if (project?.uuid) {
            await mutations.pathEqualsIgnoreQueryMutate(Endpoints.lab.project.base(project.uuid));
        }
    };

    /**
     * Archive the project.
     * @throws any API errors
     * @return {Promise<void>}
     */
    const archiveProject = async ({ project }: ProjectProps) => {
        if (!project) {
            return;
        }
        await api.post(Endpoints.lab.project.archive({ projectId: project.uuid }));
        await revalidateLabProjects({ project });
    };

    /**
     * Unarchive the project
     * @throws any API Errors
     * @return {Promise<void>}
     */
    const unarchiveProject = async ({ project }: ProjectProps) => {
        if (!project) {
            return;
        }
        await api.doDelete(Endpoints.lab.project.archive({ projectId: project.uuid }));
        await revalidateLabProjects({ project });
    };

    /**
     * Create a new project. Will re-validate all lab projects
     * @param {CreateProjectParams} values
     * @throws {ApiError} any API Errors
     * @return {Promise<Project>} the created project
     */
    const createProject = async (values: CreateProjectParams): Promise<Project> => {
        const createdProject = await api.post<Project>(Endpoints.lab.projects(), values);
        await revalidateLabProjects();
        return createdProject;
    };

    /**
     * Create a new project. Will re-validate all lab projects
     * @param {string} projectId the ID of the project to update
     * @param {CreateProjectParams} values the values to update
     * @throws {ApiError} Any API Error that is encountered
     * @return {Promise<Project>} the created project
     */
    const updateProject = async (projectId: string, values: UpdateProjectParams): Promise<Project> => {
        const updatedProject = await api.put<Project>(Endpoints.lab.project.base(projectId), {
            ...values,
            external_url_display_name: blankToNull(values.external_url_display_name),
            external_url: blankToNull(values.external_url),
            uuid: projectId,
        });
        await revalidateLabProjects();
        return updatedProject;
    };

    return { archiveProject, unarchiveProject, createProject, updateProject, revalidateLabProjects };
};

export default useProjectApi;

import TextField from '@components/forms/TextInput';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { Collapse, FormControl, IconButton, List, ListItem, MenuItem, Select, Tooltip } from '@mui/material';
import { Invite, InviteCreateParams, InviteUserParams } from '@models/Invitation';
import React, { useState } from 'react';
import { defaultRoleLevels, PermissionObjectName, RoleLevel } from '@models/Permission';
import { FormikFieldError } from '@components/forms/FieldError';
import Button from '@components/Button';
import { Add, DeleteOutlineRounded } from '@mui/icons-material';
import * as Yup from 'yup';
import { Alert } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import RoleDescriptions from '@components/dashboard/RoleDescriptions';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Schemas } from '@components/forms/Forms';
import useAuth from '@hooks/useAuth';
import { isDefined } from '@util/TypeGuards';
import cn from 'classnames';
import CopyTextInput from '@components/forms/CopyTextInput';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';
import { useRouter } from 'next/router';
import { truncateText } from '@/src/util/textUtil';

const styles = {
    root: {
        '& .MuiAlert-message': {
            width: '100%',
        },
    },
};

export type FormValues = { users: InviteUserParams[] };
export type Props = {
    roleLevels?: RoleLevel[];
    itemType: PermissionObjectName;
    onSubmit: (values: FormValues) => Promise<{
        success: Invite[];
        errors: InviteCreateParams[];
    }>;
    closeModal: () => void;
};

const createUser = (): InviteUserParams => {
    return { email: '', role_type: RoleLevel.editor };
};

const initialValues: { users: InviteUserParams[] } = { users: [createUser()] };

const Schema = Yup.object({
    users: Yup.array(
        Yup.object({
            email: Schemas.email,
        }),
    ),
});

const CreateInvitationsForm = ({ onSubmit, roleLevels = defaultRoleLevels, itemType, closeModal }: Props) => {
    const { user } = useAuth();
    const router = useRouter();
    const hasOrganization = isDefined(user?.organization);
    const [rolesExpanded, setRolesExpanded] = useState(false);

    const handleSubmit = async (values: FormValues, formik: FormikHelpers<FormValues>) => {
        const { success, errors } = await onSubmit(values);

        if (errors.length) {
            formik.setStatus({
                error: `${errors.length} invite${errors.length !== 1 ? 's' : ''} failed to send.`,
            });
        } else {
            formik.setStatus({
                success: `${success.length} invite${success.length !== 1 ? 's' : ''} sent.`,
                invites: success.map((successObj) => {
                    const { to_email, invite_link, uuid } = successObj;
                    return { to_email, invite_link, uuid };
                }),
            });
        }

        formik.setSubmitting(false);
    };

    const goToTeamTab = () => {
        closeModal();
        router.replace('/team');
    };

    return (
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={Schema}>
                {({ values, errors, touched, handleChange, isSubmitting, status }) => {
                    return (
                        <Form>
                            <FieldArray name="users">
                                {({ remove, push }) => (
                                    <div className="">
                                        <div className="grid grid-cols-3 gap-x-2 pl-1">
                                            <div className="field-label no-margin col-span-2">Email</div>
                                            <div className="field-label no-margin mb-0 flex items-center">
                                                <span className="mr-1">Role</span>
                                                <InfoOutlined
                                                    fontSize="small"
                                                    className="relative cursor-pointer text-indigo-600"
                                                    onClick={() => setRolesExpanded(!rolesExpanded)}
                                                />
                                            </div>
                                        </div>
                                        <Collapse in={rolesExpanded}>
                                            <RoleDescriptions
                                                roles={roleLevels}
                                                itemType={itemType}
                                                className="rounded-lg bg-indigo-100 p-4 text-default"
                                            />
                                        </Collapse>
                                        <List disablePadding>
                                            {values.users.map((user, index) => {
                                                return (
                                                    <ListItem disableGutters key={`user_${index}`} className="">
                                                        <div className="grid w-full grid-cols-3 gap-x-2 gap-y-2">
                                                            <div className="col-span-2 pl-0.5">
                                                                <TextField
                                                                    className="no-margin m-0 flex-grow text-left sm:mb-0 lg:opacity-80"
                                                                    name={`users.${index}.email`}
                                                                    type="email"
                                                                    onChange={handleChange}
                                                                    value={user.email}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    placeholder="name@company.com"
                                                                />
                                                                <FormikFieldError name={`users.${index}.email`} />
                                                            </div>
                                                            <div className="flex space-x-2">
                                                                <FormControl variant="outlined" fullWidth>
                                                                    <Select
                                                                        IconComponent={KeyboardArrowDownRoundedIcon}
                                                                        margin="dense"
                                                                        name={`users.${index}.role_type`}
                                                                        onChange={handleChange}
                                                                        value={values.users[index].role_type}
                                                                    >
                                                                        {roleLevels.map((roleLevel) => (
                                                                            <MenuItem key={roleLevel} value={roleLevel}>
                                                                                <div className="flex max-w-sm flex-row items-center justify-start whitespace-normal capitalize sm:max-w-lg">
                                                                                    {roleLevel}
                                                                                </div>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    <FormikFieldError
                                                                        name={`users.${index}.role_type`}
                                                                    />
                                                                </FormControl>
                                                                {values.users.length > 1 && (
                                                                    <div className="mb-2 self-end">
                                                                        <IconButton
                                                                            sx={{
                                                                                '& .MuiIconButton-label': {
                                                                                    lineHeight: 1,
                                                                                },
                                                                            }}
                                                                            onClick={() => remove(index)}
                                                                            size="small"
                                                                        >
                                                                            <DeleteOutlineRounded
                                                                                color={'error'}
                                                                                fontSize={'small'}
                                                                            />
                                                                        </IconButton>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                        <div>
                                            <Button
                                                startIcon={<Add fontSize="small" />}
                                                variant="text"
                                                color="primary"
                                                onClick={() => push(createUser())}
                                            >
                                                Add another
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </FieldArray>
                            <div className="mb-2 pt-4">
                                <Button
                                    disabled={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >{`Send Invitation${values.users.length > 1 ? 's' : ''}`}</Button>
                            </div>
                            {hasOrganization &&
                                [PermissionObjectName.project, PermissionObjectName.experiment].includes(itemType) && (
                                    <div className="mb-2 text-sm">
                                        Inviting collaborators here adds them only to this {itemType}.{' '}
                                        <span>
                                            To invite someone to your team or organization, please visit the{' '}
                                            <span
                                                className="cursor-pointer font-semibold text-primary"
                                                onClick={goToTeamTab}
                                            >
                                                Team tab
                                            </span>
                                            .
                                        </span>
                                    </div>
                                )}
                            {status?.error && (
                                <Alert severity="error" className="text-error">
                                    {status.error}
                                </Alert>
                            )}
                            {status?.success && (
                                <Alert severity="success" className={cn(styles.root, 'text-success')}>
                                    <div className="flex items-center">
                                        {status.success}
                                        <Tooltip
                                            title={'Copy invitation link to send directly to the invitee.'}
                                            placement="right"
                                            arrow
                                            className="ml-1"
                                        >
                                            <div>
                                                <HelpCircleIcon height={16} width={16} color="#3343AB" />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        {status?.invites?.map((invite, index) => (
                                            <div
                                                key={invite.uuid}
                                                className={cn('flex items-center', {
                                                    'mb-2':
                                                        status?.invites.length > 1 &&
                                                        index !== status?.invites.length - 1,
                                                })}
                                            >
                                                <span className="mr-2">{truncateText(invite.to_email, 50)}</span>
                                                <CopyTextInput
                                                    value={invite.invite_link}
                                                    noMargin
                                                    inputClasses="!text-xs text-default opacity-80"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </Alert>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
export default CreateInvitationsForm;

import { useRouter } from 'next/router';
import Head from 'next/head';
import copy from '@copy/Copy';

export type BasePageMeta = {
    title?: string;
    description?: string;
    imageUrl?: string;
    imageAltText?: string;
    videoUrl?: string;
    canonical?: string;
    ogType?: string;
};

const BaseMeta = ({ title, description, imageAltText, imageUrl, videoUrl, canonical, ogType }: BasePageMeta) => {
    const router = useRouter();
    const canonicalUrl = (`https://pluto.bio` + (router.asPath === '/' ? '' : router.asPath)).split('?')[0];

    return (
        <Head>
            <title>{title}</title>
            <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
            <link rel="alternate icon" href="/favicon.ico" />
            <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
            <meta name="description" content={description} />
            <meta property="og:image" content={imageUrl} />
            <meta name="og:title" content={title} />
            <meta name="og:description" content={description} />
            <meta name="og:url" content={canonical ? `https://pluto.bio/${canonical}` : canonicalUrl} />
            <meta name="og:type" content={ogType ? ogType : 'website'} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:name" content={copy.companyName} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={imageUrl} />
            <meta name="twitter:image:alt" content={imageAltText} />
            <meta name="twitter:description" content={description} />
            <meta property="og:video" content={videoUrl} />
            <link rel="canonical" href={canonical ? `https://pluto.bio/${canonical}` : canonicalUrl} />
        </Head>
    );
};

export default BaseMeta;

import { PermissionCheckOptions, PermissionName, UserPermissionObject } from '@models/Permission';

/**
 * Check if a given UserPermissionObject has the required parameters
 * @param {UserPermissionObject | null | undefined} object - the object to validate
 * @param {PermissionName[] | undefined} additionalPermissions - additional permissions the user has, such as top-level user permissions. This is usually not needed.
 * @param {PermissionName[] | PermissionName} requires - the permissions that are required to perform some action
 * @param {boolean | undefined} any - if set to `true` only a single match of the required permissions is needed. if false, all required permissions must be present on the given object
 * @return {boolean}
 */
export const hasPermission = (
    object: UserPermissionObject | null | undefined,
    { additionalPermissions, requires, any = false }: PermissionCheckOptions,
): boolean => {
    const allPermissions = [...(additionalPermissions ?? []), ...(object?.user_permissions ?? [])];

    const neededPermissions: PermissionName[] = Array.isArray(requires) ? requires : [requires];

    if (any) {
        return neededPermissions.some((permission) => allPermissions.includes(permission));
    } else {
        return neededPermissions.every((permission) => allPermissions.includes(permission));
    }
};

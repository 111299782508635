import { PermissionObjectName, RoleLevel } from '@models/Permission';
import { isBlank } from '@util/StringUtil';
import cn from 'classnames';

type RoleLevelCopy = Record<RoleLevel, string>;
type PermissionObjectRoleCopy = Record<PermissionObjectName, RoleLevelCopy>;

export const permissionCopy: PermissionObjectRoleCopy = {
    organization: {
        editor: 'An Editor can edit all Projects and Experiments in this Organization. They can also create new Projects and Experiments.',
        member: 'A Member can add their own Projects and Experiments in this Organization’s lab space. They can also view or edit Projects and Experiments that they’ve been invited to.',
        owner: '',
        viewer: 'A Viewer can see all Projects and Experiments in this Organization’s lab space but cannot edit or create new Projects and Experiments.',
    },
    experiment: {
        editor: 'An Editor can edit this Experiment’s data, plots, and attachments. They can also share this Experiment with other users.',
        member: 'A Member can view this Experiment and add attachments to it, but cannot share the experiment with other users.',
        owner: '',
        viewer: 'A Viewer can see this Experiment and download its attachments, but cannot edit the experiment or share it with additional users.',
    },
    biomarkerset: {
        editor: 'An Editor can edit this set’s data, plots, and attachments. They can also share this set with other users.',
        member: 'A Member can view this set and add attachments to it, but cannot share the set with other users.',
        owner: '',
        viewer: 'A Viewer can see this set and download its attachments, but cannot edit the set or share it with additional users.',
    },
    project: {
        editor: 'An Editor can create and edit Experiments in this Project, and can invite other users.',
        member: 'A Member can add their own Experiments to this Projects, but cannot invite other users.',
        owner: '',
        viewer: 'A Viewer can see all Experiments in this Project but they cannot add Experiments or invite additional users.',
    },
    team: {
        editor: 'An Editor can create and edit Projects and Experiments owned by this Team. They can also invite other users to the Team.',
        member: 'A Member can add their own Experiments in any Projects owned by this Team, but cannot invite other users to the Team.',
        owner: '',
        viewer: 'A Viewer can see Projects and Experiments owned by this Team but they cannot create Projects or Experiments or invite users to the Team.',
    },
};

type Props = { roles: RoleLevel[]; itemType: PermissionObjectName; className?: string };
const RoleDescriptions = ({ roles, itemType, className }: Props) => {
    const objectTypeCopy = permissionCopy[itemType];
    return (
        <div className={cn('space-y-4 p-2', className)}>
            {roles
                .filter((level) => !isBlank(objectTypeCopy[level]))
                .map((level) => {
                    return (
                        <div key={level}>
                            <h4 className="font-semibold capitalize text-dark">{level}</h4>
                            <p className="text-dark opacity-70">{objectTypeCopy[level]}</p>
                        </div>
                    );
                })}
        </div>
    );
};

export default RoleDescriptions;
